import React, { Component } from "react";
import CreateFamilyForm from "../components/createfamily/CreateFamilyForm";

class CreateFamilyPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <CreateFamilyForm />
      </div>
    );
  }
}

export default CreateFamilyPage;
