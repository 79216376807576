import React, { Component } from "react";
import LoginForm from "../components/login/loginform";

class LoginPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <LoginForm />
      </React.Fragment>
    );
  }
}

export default LoginPage;
