import React, { Component } from "react";
import SignupForm from "../components/signup/signupform";

class SignupPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <SignupForm />
      </div>
    );
  }
}

export default SignupPage;
