import React, { Component } from "react";

class UserDescription extends Component {
  state = {};
  render() {
    return (
      <div id="UserDescriptionContent">
        This user doesn't have a description
      </div>
    );
  }
}

export default UserDescription;
